'use client'

import { useState, useEffect } from 'react'
import { MapContainer, TileLayer, Marker, Popup, GeoJSON, Polyline } from 'react-leaflet'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { Button } from "./components/ui/Button"
import { Car, Bike, User, Train } from 'lucide-react'
import { FeatureCollection, GeoJsonObject, Point, MultiPoint } from 'geojson'

type GeoJSONData = FeatureCollection;

// Fix for Leaflet's default icon
delete (L.Icon.Default.prototype as any)._getIconUrl
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png',
})

// Función para crear un ícono personalizado para las paradas
const stopStyle = (feature: any) => {
  const color = feature?.properties?.color || 'blue'; // Usa el color definido en el GeoJSON o azul por defecto
  return L.divIcon({
    className: '', // No agregamos clases personalizadas para evitar estilos conflictivos
    html: `<div style="background-color: ${color}; width: 15px; height: 15px; border-radius: 50%; border: 2px solid white;"></div>`,
    iconSize: [15, 15],
    iconAnchor: [7.5, 7.5],
    popupAnchor: [0, -7.5],
  });
}

export default function Component() {
  const [mode, setMode] = useState('WALK')
  const [time, setTime] = useState('')
  const [routeData, setRouteData] = useState<GeoJSONData | null>(null) // Estado para rutas GeoJSON
  const [stopsData, setStopsData] = useState<GeoJSONData | null>(null) // Estado para paradas GeoJSON
  const [simulatedRoute, setSimulatedRoute] = useState<[number, number][]>([]) // Estado para la ruta simulada
  const [showSimulation, setShowSimulation] = useState(false) // Controla cuándo mostrar la simulación

  // Centro del mapa (ejemplo en Cusco)
  const center: [number, number] = [-13.525, -71.972]

  // Cargar GeoJSON al inicio
  useEffect(() => {
    fetchGeoJSONData();
  }, []);

  // Función para cargar GeoJSON
  const fetchGeoJSONData = () => {
    // Fetch rutas GeoJSON
    fetch('/lineas.geojson') // Reemplaza con la ruta de tu archivo GeoJSON de rutas
      .then(response => response.json())
      .then(data => {
        console.log('Rutas cargadas:', data); // Imprimir datos de las rutas
        setRouteData(data as GeoJSONData);
      })
      .catch(error => console.error('Error loading routes GeoJSON:', error));

    // Fetch paradas GeoJSON
    fetch('/paradas.geojson') // Reemplaza con la ruta de tu archivo GeoJSON de paradas
      .then(response => response.json())
      .then(data => {
        console.log('Paradas cargadas:', data); // Imprimir datos de las paradas
        setStopsData(data as GeoJSONData);
      })
      .catch(error => console.error('Error loading stops GeoJSON:', error));
  }

  // Función para manejar el clic en "Search Route"
  const handleSearchRoute = () => {
    simulateRoute();
    setShowSimulation(true); // Mostrar la ruta simulada
  }

  // Función para simular una ruta
  const simulateRoute = () => {
    const origin: [number, number] = [-13.53195, -71.967462] // Cusco - Plaza de Armas
    const destination: [number, number] = [-13.517088, -71.978535] // Cusco - Sacsayhuamán
    const route: [number, number][] = [
      origin,
      [-13.529, -71.970], // Punto intermedio 1
      [-13.527, -71.973], // Punto intermedio 2
      [-13.523, -71.975], // Punto intermedio 3
      destination
    ]
    setSimulatedRoute(route)

    // Simular tiempo de viaje según el modo
    let simulatedTime = ''
    switch (mode) {
      case 'CAR':
        simulatedTime = '6 hours 30 minutes'
        break
      case 'TRAIN':
        simulatedTime = '2 hours 30 minutes'
        break
      case 'BIKE':
        simulatedTime = '3 days 5 hours'
        break
      case 'WALK':
        simulatedTime = '12 days 8 hours'
        break
    }
    setTime(simulatedTime)
  }

  // Función para aplicar estilos a las rutas
  const routeStyle = (feature: any) => {
    return {
      color: feature?.properties?.color || 'blue', // Usa el color del GeoJSON o por defecto azul
      weight: 3,
      opacity: 0.7,
    }
  }

  return (
    <div className="h-screen flex flex-col">
      <div className="p-4 space-x-4 flex justify-center items-center">
        <Button
          variant={mode === 'WALK' ? 'default' : 'outline'}
          onClick={() => setMode('WALK')}
        >
          <User className="h-4 w-4" />
        </Button>
        <Button
          variant={mode === 'BIKE' ? 'default' : 'outline'}
          onClick={() => setMode('BIKE')}
        >
          <Bike className="h-4 w-4" />
        </Button>
        <Button
          variant={mode === 'CAR' ? 'default' : 'outline'}
          onClick={() => setMode('CAR')}
        >
          <Car className="h-4 w-4" />
        </Button>
        <Button
          variant={mode === 'TRAIN' ? 'default' : 'outline'}
          onClick={() => setMode('TRAIN')}
        >
          <Train className="h-4 w-4" />
        </Button>
        <Button onClick={handleSearchRoute}>Search Route</Button>
      </div>
      {showSimulation && time && (
        <div className="text-center p-2 bg-primary text-primary-foreground">
          Estimated travel time: {time}
        </div>
      )}
      <div className="flex-grow">
        <MapContainer center={center} zoom={13} style={{ height: '500px', width: '100%' }}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {/* Mostrar rutas GeoJSON */}
          {routeData && (
            <GeoJSON data={routeData as GeoJsonObject} style={routeStyle} />
          )}
          {/* Mostrar paradas como marcadores */}
          {stopsData && stopsData.features.map((feature, index) => {
            if (feature.geometry.type === 'MultiPoint') {
              // Obtener la primera coordenada del MultiPoint
              const coordinates = (feature.geometry as MultiPoint).coordinates[0];
              console.log('Coordenadas de la parada (MultiPoint):', coordinates); // Imprimir coordenadas de la parada
              if (coordinates && coordinates.length === 2) {
                return (
                  <Marker
                    key={index}
                    position={[coordinates[1], coordinates[0]]}
                    icon={stopStyle(feature)} // Usar la función para crear un ícono personalizado
                  >
                    <Popup>{feature.properties?.Nombre || 'Stop'}</Popup>
                  </Marker>
                );
              }
            }
            return null;
          })}
          {/* Mostrar ruta simulada */}
          {showSimulation && simulatedRoute.length > 0 && (
            <>
              <Marker position={simulatedRoute[0]}>
                <Popup>Origin</Popup>
              </Marker>
              <Marker position={simulatedRoute[simulatedRoute.length - 1]}>
                <Popup>Destination</Popup>
              </Marker>
              <Polyline positions={simulatedRoute} color="blue" />
            </>
          )}
        </MapContainer>
      </div>
    </div>
  )
}
